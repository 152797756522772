import React from 'react'
export default function jobComponent({ imgLogo, jobName, jobTitle, jobLocation, jobDuration, info1, info2, info3}) {
    return (
        <div className='shadow-lg mt-2 p-3 bg-white rounded resumeContainer'>
            <div className='Resume'>
                <div className='d-flex flex-row'>
                    <img src={imgLogo} alt={imgLogo} />
                    <div className='resumeHeading w-100'>
                        <h3 className='font-weight-bold'>{jobName}</h3>
                        <h5 className='font-weight-bold'>{jobTitle}</h5>
                        <div className='d-flex'>
                            <h6 className='font-weight-bold'>{jobLocation}</h6>
                            <h6 className='ml-auto font-weight-bold'>{jobDuration}</h6>
                        </div>
                    </div>
                </div>
                <hr className='w-100'/>
                <div className='font-weight-bold'>
                    <ul>
                        <li className={info1 === ""? "d-none":""}>{info1}</li>
                        <li className={info2 === ""? "d-none":""}>{info2}</li>
                        <li className={info3 === ""? "d-none":""}>{info3}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
