import React from 'react'
import "../styles/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderBar from '../components/headerBar';
import ResumeSection from '../components/resumeSection'
export default function resume() {
  return (
    <div>
        <HeaderBar/>
        <ResumeSection/>
    </div>
  )
}