import React from 'react'
import JobComponent from "../components/jobComponent"
import craLogo from "../images/CanadaRevenueAgency.svg"
import magnaLogo from "../images/magnaLogo.svg"
import SchoolLogo from "../images/OntarioTechUniversityLogo.svg"
import { FaEye } from "react-icons/fa";
import Resume from "../static/JoshuaRamnaraineResume.pdf";
export default function resumeSection() {
  return (
    <div className='resumePage'>
        <h1 className='font-weight-bold'>Resume <a href={Resume} target="_blank"><FaEye/></a></h1>

        <h1 className='font-weight-bold pt-3'>Education</h1>
        <JobComponent
            imgLogo = {SchoolLogo}
            jobName = "Ontario Tech University"
            jobTitle ="Bachelor of Engineering - B.E., Software Engineering"
            jobLocation="Oshawa, Ontario"
            jobDuration = "September 2018 - May 2023"
            info1 = "Programming related course work: Object Oriented Programming, Data Structures,Design and Analysis of Algorithms, Software Project Management"
            info2 = ""
            info3 = ""
        />
        <h1 className='font-weight-bold pt-5'>Professional Experience</h1>
        <br/>
        <JobComponent
            imgLogo = {craLogo}
            jobName = "Canada Revenue Agency"
            jobTitle ="Software Developer"
            jobLocation="Scarborough, Ontario"
            jobDuration = "May 2022 - September 2022"
            info1 ="Developed a Control Log tracking tool to assist the Control team in managing their workload using Excel VBA and PostgreSQL."
            info2 = "Developed an Economic Entity tool which involves VBScripts scraping data and generating reports."
            info3 = "Collaborated with analysts to modify a data scraping tool from VBScripts to SQL queries and developed a macro to work with new datasets."
        />
        <br/>
        <JobComponent
            imgLogo = {craLogo}
            jobName = "Canada Revenue Agency"
            jobTitle ="Software Developer"
            jobLocation="Scarborough, Ontario"
            jobDuration = "May 2021 - September 2021"
            info1 ="Joined a pilot team that creates tools and solutions to automate work processes for stakeholders within the organization."
            info2 = "Developed a project management tool that enabled teams to track and modify project details using Excel VBA and PostgreSQL, which made it easier for team leaders to keep track of their workload. "
            info3 = "Collaborated with stakeholders and colleagues to brainstorm ideas of tools that would automate the work of FTEs."
        />
        <br/>
        <JobComponent
            imgLogo = {magnaLogo}
            jobName = "Magna Dortec Industries"
            jobTitle ="Testing Technician"
            jobLocation="Newmarket, Ontario"
            jobDuration = "May 2019 - August 2019"
            info1 ="Worked on a new production line where my role was to analyze and document failed parts, then rework the part if possible, to continue sending it on the line."
            info2 = "Inspected preassembled components and raw materials before they are assembled on the production line."
            info3 = "Collaborated with Quality Engineers on Ford Explorer Keypad to devise a solution for manufacturing their product."
        />
        <br/>
    </div>
  )
}

